import {
  formatOccupantsShort,
  getOccupanciesFromReservations,
} from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { useOrder } from '../api/order'
import { AccommodationItem } from 'tripPlanner/types/tripItem'
import { getOrderId } from 'tripPlanner/utils'

export default function useAccommodationItemOccupancyDescription(
  item: AccommodationItem | undefined,
): { occupanciesText?: string; numberOfRoomsText?: string } {
  const orderId = item ? getOrderId(item) : undefined

  const { data: order } = useOrder(orderId)

  if (!item) {
    return {}
  }

  if (orderId) {
    // This is an LE hotel booking
    if (order?.items.length) {
      const reservations = order.items
        .filter(
          (orderItem) =>
            orderItem.reservation && orderItem.offerId === item.code,
        )
        .map((orderItem) => orderItem.reservation!)
      const occupancies = getOccupanciesFromReservations(...reservations)
      const occupanciesText = formatOccupantsShort(occupancies)
      const numberOfRoomsText =
        reservations.length > 0 ?
          pluralizeToString('room', reservations.length) :
          undefined
      return { occupanciesText, numberOfRoomsText }
    }
  } else if (item.savedItemData?.occupancies) {
    // This is a bookmark
    const occupanciesText = formatOccupantsShort(item.savedItemData.occupancies)
    const numberOfRooms = item.savedItemData.occupancies.length
    const numberOfRoomsText =
      numberOfRooms > 0 ? pluralizeToString('room', numberOfRooms) : undefined
    return { occupanciesText, numberOfRoomsText }
  }

  return {}
}
