import { UseMutationResult, useQueryClient, useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import { refreshTripOrders } from 'tripPlanner/api'
import { RefreshTripError } from 'tripPlanner/api/types'
import * as Mapper from 'tripPlanner/mappers'
import { RefreshedTrip, FullTrip } from 'tripPlanner/types/common'
import * as TripKeys from '../reactQueryKeys/trips'
import { getOrders, getReservationDetailsForItems, getBrandOrders, getFullOrder } from 'api/order'
import { arrayToObject } from 'lib/array/arrayUtils'
import * as OrderKeys from '../reactQueryKeys/orders'
import { NON_TRIP_STALE_TIME } from './common'
import { reqGetTourOrderTravellerDetails } from 'api/traveller'

export const useRefreshTripOrders = ({
  onSuccess, onError,
}: {
  onSuccess: (updateCount: number) => void
  onError: (err: RefreshTripError) => void
}): UseMutationResult<
  RefreshedTrip, RefreshTripError, Parameters<typeof refreshTripOrders>[0]
> => {
  const queryClient = useQueryClient()
  return useMutation(
    (params) => refreshTripOrders(params).then(Mapper.refreshedTrip),
    {
      retry: false,
      onSuccess: (data, tripId) => {
        const { changeCount, ...trip } = data
        if (changeCount > 0) {
          queryClient.setQueryData<FullTrip>(TripKeys.detail(tripId), trip)
        }
        onSuccess(changeCount)
      },
      onError,
    },
  )
}
export const useOrdersByMemberId = (
  regionCode: string,
  memberId?: string,
  enabled: boolean = true,
  pageSize: number = 50,
): UseQueryResult<Array<App.Order>> => {
  return useQuery(
    OrderKeys.detailsByMemberId(regionCode, memberId),
    async() => {
      if (!memberId) {
        return []
      }

      // The following code has been adopted from src/client/actions/OrderActions.ts:fetchOrdersForCurrentUser.
      // The getDepositDetails has been left out as it doesn't have a use-case in TripPlanner yet, once we do
      // we can add that too.
      const ordersPerMemberId: Array<App.Order> = []
      let end = false
      let page = 1
      while (!end) {
        const results = await getOrders(memberId, regionCode, {
          page,
          pageSize,
        })
        const ordersWithReservationDetails = await Promise.all(
          results.orders.map((order) => getReservationDetailsForItems(
            order.items.filter((item) => item.reservationMade),
            order.id,
          ).then((reservations) => {
            // now we...*shudder* mutate the order with our reservation details
            const reservationsById = arrayToObject(
              reservations,
              (res) => res.itemId,
            )
            order.items = order.items.map((item) => {
              if (reservationsById[item.id]) {
                return {
                  ...item,
                  reservation: reservationsById[item.id],
                }
              }
              return item
            })
            return order
          }),
          ),
        )

        ordersPerMemberId.push(...ordersWithReservationDetails)

        end = page * pageSize >= results.total
        page++
      }

      const brandOrders = await getBrandOrders(memberId)
      if (brandOrders.length > 0) {
        ordersPerMemberId.push(...brandOrders)
      }

      return ordersPerMemberId
    },
    {
      enabled: !!memberId && enabled,
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

export const useOrderTravellers = (
  orderId: string | undefined,
): UseQueryResult<Array<App.OrderTraveller>> => useQuery(
  ['orderTravellers', orderId],
  () => orderId ?
    reqGetTourOrderTravellerDetails(orderId).then(
      (resp) => resp.travellers,
    ) :
    Promise.resolve(undefined),
  {
    enabled: !!orderId,
    staleTime: NON_TRIP_STALE_TIME,
  },
)

export const useOrder = (
  orderId: string | undefined,
): UseQueryResult<App.Order | undefined, unknown> => useQuery(
  OrderKeys.detail(orderId),
  () => orderId ?
    getFullOrder(orderId) :
    Promise.resolve(undefined),
  {
    staleTime: NON_TRIP_STALE_TIME,
  },
)
