import moment from 'moment'
import { useEffect, useMemo } from 'react'

import { fetchAvailableRatesForOffer } from 'actions/OfferActions'
import { useAppDispatch } from 'hooks/reduxHooks'
import useOfferAvailableRates from 'hooks/useOfferAvailableRates'
import { initialRooms } from 'lib/offer/occupancyUtils'
import { isBedbankOffer, isLEOffer } from 'lib/offer/offerTypes'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import { SavedHotelData } from 'tripPlanner/types/tripItem'
import { isBookmarkedHotelOfferAvailable } from 'tripPlanner/utils/offer'

const useAccommodationOfferAvailable = (
  offer:
    | App.BedbankOffer
    | App.Tours.TourV2Offer
    | App.Offer
    | App.CruiseOffer
    | undefined,
  savedItemData?: SavedHotelData,
  offerId: string = '',
) => {
  const offerAvailableRates = useOfferAvailableRates(offer?.id, {
    checkIn: savedItemData?.checkIn,
    checkOut: savedItemData?.checkOut,
    rooms: savedItemData?.occupancies ?? initialRooms,
  })

  const minBookableCheckin = moment().add(2, 'days')
  const hasBookableDates = !!(
    savedItemData?.checkIn &&
    savedItemData.checkOut &&
    moment(savedItemData.checkIn).isSameOrAfter(minBookableCheckin, 'day')
  )

  const reduxDispatch = useAppDispatch()

  useEffect(() => {
    if (offer && savedItemData && hasBookableDates) {
      const { checkIn, checkOut, occupancies } = savedItemData

      reduxDispatch(
        fetchAvailableRatesForOffer(offer as App.Offer, {
          checkIn: checkIn!,
          checkOut: checkOut!,
          occupants: occupancies ?? initialRooms,
        }),
      )
    }
  }, [savedItemData, offer, reduxDispatch, hasBookableDates])

  return useMemo(() => {
    if (!offer) return true

    if (isLEOffer(offer)) {
      const available = scheduleIsCurrent(offer.onlinePurchaseSchedule)

      if (!available) return false

      if (offerAvailableRates.fetching) return true

      if (offerAvailableRates.rates.length === 0 && hasBookableDates) return false

      if (
        savedItemData &&
        hasBookableDates &&
        offerAvailableRates &&
        offerAvailableRates.rates.length > 0
      ) {
        return isBookmarkedHotelOfferAvailable(
          offerId,
          offer.packages,
          savedItemData,
          offerAvailableRates.rates,
        )
      }
      return true
    } else if (isBedbankOffer(offer)) {
      return !savedItemData?.isOfferSoldOut
    }

    return true
  }, [offer, savedItemData, hasBookableDates, offerAvailableRates, offerId])
}
export default useAccommodationOfferAvailable
