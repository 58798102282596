import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'

import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalContext from 'contexts/ModalContext'
import BaseBookmarkButton from 'tripPlanner/components/Bookmark/Common/BaseBookmarkButton'
import DeleteTripItem from 'tripPlanner/components/TripModal/TripItems/DeleteTripItem'
import { TripItem } from 'tripPlanner/types/tripItem'

const BookmarkButton = styled(BaseBookmarkButton)`
  @media print {
    display: none;
  }
`

interface Props {
  item: TripItem
}

function SavedItemRemoveButton({ item }: Props) {
  const showModal = useContext(ModalContext)
  const deleteItem = useCallback(() => {
    showModal(
      <ModalBase>
        <DeleteTripItem item={item} />
      </ModalBase>,
    )
  }, [item, showModal])
  return <BookmarkButton onClick={deleteItem} isSaved />
}

export default SavedItemRemoveButton
